import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Slider from "react-slick";
import {StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./homeartists.css"
import artists from "../../artists.json";

export default function HomeArtists() {

    const isBrowser = typeof window !== 'undefined'
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)
    const [isVertical, setIsVertical] = useState(true);

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
    }, []);

    useEffect(() => {
        if (width > 992) {
            setIsVertical(true);
        } else {
            setIsVertical(false)
        }
    }, [width])

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: isVertical,
        verticalSwiping: isVertical,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };
    return (
        <div>
            <Slider {...settings}>
                {
                    artists.data.map(artist =>
                        <div>
                            <div className="artists-item">
                                <div className="artists-img">
                                    <img src={"./" + artist.img} alt={artist.name} loading={"lazy"} decoding={"async"} style={{width:"100%"}}/>
                                </div>
                                <div className="artists-desc">
                                    <h3>{artist.name}</h3>
                                    <span className="sub-title">
                                        <span>{artist.ability}</span>
                                        <span className="small">{artist.lang}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Slider>
        </div>
    );
}
