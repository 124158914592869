import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import HomeArtists from "../components/homeartists";
import Contactform from "../components/contactform";
import {StaticImage} from "gatsby-plugin-image";
import Mouse from "../assets/Mouse.svg";
import Arrow from "../assets/Arrow1.svg";
import Play from "../assets/Play.svg";
import {Helmet} from "react-helmet";
import artists from "../artists.json";

const Artysci = () => {
    return (
        <Layout>
            <Helmet>
                <title>Artyści - Saksofon / Skrzypce / Wokale - DJ Piotr Szymański</title>
                <meta name="description" content="Muzyka na weselu czy event może być zawsze urozmaicona dodatkowymi artystami m.in. saksofon / skrzypce czy wokale." />
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>
            <Hero className="">
                <StaticImage
                    src="../images/artysci-hero.png"
                    alt="Artyści"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title subpage">
                    <h1>Artyści na Wasze wesele</h1>
                    <h3 className="line-before">Saksofon / Skrzypce / Wokal</h3>
                </div>
            </Hero>
            {
                artists.data.map((artist, key) => (
                    <section className={`home-about about-border art ${key%2 && `left`}`}>
                        <div className="about-img">
                            <img src={"../../" + artist.img} alt={artist.name} loading={"lazy"} decoding={"async"}  style={{width:"100%"}}/>

                        </div>
                        <div className="about-text about-border">
                            <h2>{artist.name}</h2>
                            <span className="sub-title">{artist.ability}
                                <span className="small">{artist.lang}</span>
                            </span>

                            <Arrow className="arrow-down icon-white arrow-sub-title"/>
                            <p>
                                {artist.body}
                            </p>
                        </div>
                    </section>
                ))
            }

            <Contactform/>
        </Layout>
    );
};
export default Artysci;
